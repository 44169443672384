export function areSignedUrlsEqual(urlA?: string, urlB?: string): boolean {
  if (!urlA || !urlB) return false;

  const parsedUrlA = new URL(urlA);
  const parsedUrlB = new URL(urlB);

  return (
    parsedUrlA.origin === parsedUrlB.origin &&
    parsedUrlA.pathname === parsedUrlB.pathname
  );
}

export function cutOffLastPathSegmentFromUrl(url: string): string {
  const urlParts = url.split('/');
  urlParts.pop();
  return urlParts.join('/');
}
