import { RequestDocument } from 'graphql-request';
import {
  BASIC_TEXT_BRANDING_QUERY,
  KARAOKE_TEXT_BRANDING_QUERY,
  SHOWCASE_BRANDING_QUERY,
} from './branding-gql';
import { MEDIA_QUERY_FRAGMENT, VIDEO_FILE_GQL_FRAGMENT } from './media-gql';
import {
  REFERENCING_SHARED_CONTENT,
  REFERENCING_CAPTION,
} from './shared-content-gql';

export const SHOWCASE_QUERY: RequestDocument = `query getShowcase($slug: String!) {
  showcase(filter: {slug: {eq: $slug}}) {
    id
    title
    organization
    slug
    logo {
      url
    }
    mainImage {
      url
    }
    stories {
      id
      originalVideo {
        url
      }
      useDatoVideo
    }
    organizationArtifacts ${MEDIA_QUERY_FRAGMENT}
    organizationLogos ${MEDIA_QUERY_FRAGMENT}
    organizationArtifactsVideo ${VIDEO_FILE_GQL_FRAGMENT}
    lowerThirdTextBranding ${BASIC_TEXT_BRANDING_QUERY}
    karaokeTextBranding ${KARAOKE_TEXT_BRANDING_QUERY}
    lastUsedTemplates
    branding ${SHOWCASE_BRANDING_QUERY}
  }
}`;

export const ALBUM_QUERY: RequestDocument = `query getAlbum($id: ItemId) {
  showcase(filter: {id: {eq: $id}}) {
    id
    title
    stories {
      id
      title
      aiResponse
      byExternalUser
      externalUploadStatus
      transcription {
        jobStatus
      }
      uploadLog {
        ffmpegProcessing
        combineResults
      }
      storyTeller {
        name
      }
      _allReferencingSharedContents
      ${REFERENCING_SHARED_CONTENT}
      _publishedAt
      thumbnail {
        url
      }
      useDatoVideo
      originalVideo
        ${VIDEO_FILE_GQL_FRAGMENT}
      finalVideo {
        thumbnail {
          url
        }
        videoFilePrimary
        ${VIDEO_FILE_GQL_FRAGMENT}
      }
      otherVideos {
        id
        title
        videoStatus
        sourcePlatform
        isClientReady
        isHidden
        clipJson
        lastActionJson
        videoJson
        thumbnail {
          url
        }
        shareableImageId
        videoFilePrimary
          ${VIDEO_FILE_GQL_FRAGMENT}
        slug
        hash
        aspectRatio
        aiGeneratedContent {
          id
          prompt
          generatedContent
        }
        associatedVideos {
          id
          aspectRatio
          videoStatus
          sourcePlatform
          isClientReady
          isHidden
          clipJson
          lastActionJson
          thumbnail {
            url
          }
          shareableImageId
          videoFilePrimary
            ${VIDEO_FILE_GQL_FRAGMENT}
        }
        _allReferencingSharedContents
        ${REFERENCING_SHARED_CONTENT}
        _allReferencingCaptions ${REFERENCING_CAPTION}
        _publishedAt
      }
    }
    organizationArtifacts ${MEDIA_QUERY_FRAGMENT}
    organizationArtifactsVideo ${MEDIA_QUERY_FRAGMENT}
    organizationLogos ${MEDIA_QUERY_FRAGMENT}
    contributors {
      id
      name
      email
    }
    logo {
      url
    }
    mainImage {
      url
    }

    lowerThirdTextBranding ${BASIC_TEXT_BRANDING_QUERY}
    karaokeTextBranding ${KARAOKE_TEXT_BRANDING_QUERY}
    lastUsedTemplates
    branding ${SHOWCASE_BRANDING_QUERY}
  }
}`;

export const ALBUM_STORY_IDS_QUERY: RequestDocument = `query getAlbum($id: ItemId) {
  showcase(filter: {id: {eq: $id}}) {
    stories {
      id
    }
  }
}`;
