import { toJS } from 'mobx';
import { TranscriptChange, TranscriptElement } from '../../types.ts/transcript';
import ChangeHandler from './ChangeHandler';

class ShiftChangeHandler extends ChangeHandler {
  apply(
    transcriptElementsMutable: TranscriptElement[],
    change: TranscriptChange & { type: 'shift' },
  ) {
    let elements = toJS(transcriptElementsMutable);

    if (change.newIndex !== change.index) {
      const addedSegment = elements
        .slice(change.index, change.index + change.count)
        .map(
          (el) =>
            ({
              ...el,
              state: !el.state || el.state === 'cut' ? 'added' : el.state,
            }) as TranscriptElement,
        );

      elements = elements
        .map((el, idx) => {
          if (idx >= change.index && idx < change.index + change.count) {
            return el.state === 'added' ? null : { ...el, state: 'cut' };
          }
          return el;
        })
        .filter(Boolean) as TranscriptElement[];

      elements.splice(change.newIndex, 0, ...addedSegment);
    }

    const timeChange = change.timeShift;
    for (let j = change.newIndex; j < change.newIndex + change.count; j++) {
      const element = elements[j];
      if (element.type === 'text') {
        element.ts += timeChange;
        element.end_ts += timeChange;
      }
    }

    transcriptElementsMutable.splice(
      0,
      transcriptElementsMutable.length,
      ...elements,
    );
  }
}

export default ShiftChangeHandler;
