import { RequestDocument } from 'graphql-request';

export const REFERENCING_SHARED_CONTENT = `
{
  id
  socialProfileGroup {
    id
  }
  video {
    id
    title
  }
  shareableContent {
    id
    title
  }
  story {
    id
  }
  organization {
    id
  }
  external
  postId
  postUrl
  lastFetched
  postType
  platform
  post
  title
  likes
  comments
  shares
  clicks
  views
  }
`;

export const SHARABLE_SHARED_CONTENT_QUERY: RequestDocument = `query getSharedContents($id: String) {
  allSharedContents(filter: {sharableContent: {eq: $id}}) _allReferencingSharedContents ${REFERENCING_SHARED_CONTENT}
}`;

export const REFERENCING_CAPTION = `
{
  id
  platform
  caption
  story{
    id
  }
  shareable{
    __typename
  }
}`;
