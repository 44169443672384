import { RequestDocument } from 'graphql-request';

export const PERSON_BY_EMAIL_QUERY: RequestDocument = `query getPersonByEmail($email: String!) {
  allPeople(filter: {email: {eq: $email}}) {
    id
    name
  }
}`;

export const PERSON_BY_NAME_QUERY: RequestDocument = `query getPersonByName($name: String!) {
  allPeople(filter: {name: {matches: {pattern: $name}}}) {
    id
    title
  }
}`;

export const TRANSCRIPTION_QUERY_FRAGMENT = `
{
  jobStatus
  audio {
    url
  }
  elementsJson {
    id
    url
  }
  waveformData {
    id
    url
  }
}`;
