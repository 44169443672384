import { RequestDocument } from 'graphql-request';
import {
  REFERENCING_SHARED_CONTENT,
  REFERENCING_CAPTION,
} from './shared-content-gql';

export const VIDEO_FILE_GQL_FRAGMENT = `
{
  id
  title
  format
  mimeType
  url
  width
  height
  url
  video {
    framerate
    duration
    thumbnailUrl
    muxPlaybackId
    mp4UrlHigh: mp4Url(exactRes: high, res: high)
    mp4UrlMedium: mp4Url(exactRes: medium, res: medium)
    mp4UrlLow: mp4Url(exactRes: low, res: low)
    mp4Url
  }
  customData
  _createdAt
}`;

export const SONG_QUERY_FRAGMENT = `{
  id
  title
  duration
  metadataStatus
  waveformUploadId
  song {
    id
    url
    title
    tags
    customData
  }
}`;

export const MEDIA_QUERY_FRAGMENT = `{
  id
  title
  customData
  format
  mimeType
  url
  width
  height
  responsiveImage {
    base64
    sizes
    src
    srcSet
    alt
    title
  }
  video {
    duration
    muxPlaybackId
    thumbnailUrl
    mp4UrlHigh: mp4Url(exactRes: high, res: high)
    mp4UrlMedium: mp4Url(exactRes: medium, res: medium)
    mp4UrlLow: mp4Url(exactRes: low, res: low)
    mp4Url
  }
  _createdAt
}`;

export const VIDEO_FILES_QUERY: RequestDocument = `query getVideoFilesFormatted($id: ItemId) {
  video(filter: {id: {eq: $id}}) {
    id
    title
    videoJson
    associatedVideos {
      id
      aspectRatio
      videoFilePrimary
        ${VIDEO_FILE_GQL_FRAGMENT}
    }
    _allReferencingVideos(through: {fields: {anyIn: video_associatedVideos}}) {
      id
      title
      aspectRatio
      associatedVideos {
        id
        aspectRatio
      }
      videoFilePrimary
        ${VIDEO_FILE_GQL_FRAGMENT}
      clipJson
      videoJson
    }
    _allReferencingSharedContents ${REFERENCING_SHARED_CONTENT}
    _allReferencingCaptions ${REFERENCING_CAPTION}
    thumbnail {
      id
      url
      responsiveImage {
        src
        srcSet
      }
    }
    videoFilePrimary
      ${VIDEO_FILE_GQL_FRAGMENT}
    lastActionJson
  }
}`;

export const STOCK_MUSIC_QUERY: RequestDocument = `query {
  allMusics{
    id
    genre
    collection {
      id
      url
      title
      tags
      customData
    }
  }
}`;

export const MY_AUDIO_QUERY: RequestDocument = `query getMyAudios($ids: [ItemId]){
  allMyAudios(filter: {id: {in: $ids}})
  ${SONG_QUERY_FRAGMENT}
}`;

export const VIDEOS_RENDERING_STATUS_QUERY: RequestDocument = `query getVideos($ids: [ItemId]) {
  allVideos(filter: {id: {in: $ids}}) {
    id
    renderId
    videoStatus
    videoFilePrimary
      ${VIDEO_FILE_GQL_FRAGMENT}
    isHidden
    aspectRatio
    lastActionJson
  }
}`;
