import { ElementBase } from 'creatomate';
import { TranscriptElement } from '../../types.ts/transcript';

// WIP: Abstraction of Creatomate format of a timeline clip.
export default class TimelineClip {
  track: number;
  localTime: number;
  globalTime: number;
  duration: number;
  exitDuration: number;
  source: {
    // TODO: Creatomate Source
    id: string;
    type: string;
    track: number;
    time: number;
    duration: number;
    locked: boolean;
    source: string;
  };
  mediaDuration: number;
  trimStart: number;

  constructor(element: any) {
    this.track = element.track;
    this.localTime = element.localTime;
    this.globalTime = element.globalTime;
    this.duration = element.duration;
    this.exitDuration = element.exitDuration;
    this.source = element.source; // Direct assignment for source
    this.mediaDuration = element.mediaDuration;
    this.trimStart = element.trimStart;
  }

  startTime() {
    return this.globalTime;
  }

  endTime() {
    return this.startTime() + this.duration;
  }

  getTextContent(transcriptElements: TranscriptElement[]): string {
    return transcriptElements
      .filter((element) =>
        this.isWithinTimeRange(element, this.globalTime, this.duration),
      )
      .filter((element) => this.isValidElement(element))
      .map((element) => element.value)
      .join(' ');
  }
  getTranscriptElements(
    allTranscriptElements: TranscriptElement[],
  ): TranscriptElement[] {
    let started = false;
    const result: TranscriptElement[] = [];

    // Step through transcript
    // Remove all elements (incl punct) until we find a matching transcript time.
    // Then include everything
    // Then bail once we leave the clip.
    for (const element of allTranscriptElements) {
      if (element.state === 'cut' || element.state === 'removed') {
        continue;
      }

      if (element.type === 'punct') {
        if (started) {
          result.push(element);
        }
        continue;
      }

      if (this.isWithinTimeRange(element, this.globalTime, this.duration)) {
        if (!started) {
          started = true;
        }
        result.push(element);
      } else if (started) {
        break;
      }
    }

    return result;
  }

  private isWithinTimeRange(
    element: TranscriptElement,
    globalTime: number,
    duration: number,
  ): boolean {
    const startTime = globalTime;
    const endTime = globalTime + duration;

    // Use 0 / Infinity to avoid Typescript null checks.
    return (
      (element.ts ?? 0) >= startTime && (element.end_ts ?? Infinity) <= endTime
    );
  }

  private isValidElement(element: TranscriptElement): boolean {
    return Boolean(
      element.value && (element.type === 'text' || element.type === 'punct'),
    );
  }
}
