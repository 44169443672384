import { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import styled, { css } from 'styled-components';

import UploadIcon from '../../svgs/UploadIcon';
import SpinningLoading from '../SpinningLoading';

export type DropzoneAccept = 'all' | 'images' | 'videos' | 'documents';

type Props = {
  onDrop: (files: File[]) => void;
  children?:
    | React.ReactNode
    | ((params: { openDialog: () => void }) => React.ReactNode);
  accept?: DropzoneAccept;
  isEmpty?: boolean;
  message?: string;
  isUploading?: boolean;
};

const Dropzone = (props: Props) => {
  const {
    onDrop,
    children,
    accept = 'all',
    isEmpty,
    message,
    isUploading,
  } = props;
  const handleDrop = useCallback((files: File[]) => onDrop(files), [onDrop]);
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    open: openDialog,
  } = useDropzone({
    onDrop: handleDrop,
    accept:
      accept !== 'all'
        ? {
            ...(accept === 'images' && {
              'image/*': ['.png', '.gif', '.jpeg', '.jpg'],
            }),
            ...(accept === 'videos' && { 'video/mp4': ['.mp4'] }),
            ...(accept === 'documents' && { 'application/pdf': ['.pdf'] }),
          }
        : {
            'image/*': ['.png', '.gif', '.jpeg', '.jpg'],
            'video/mp4': ['.mp4'],
          },
    disabled: isUploading,
    noClick: !isEmpty,
  });
  const shouldShowDropMessage = !isUploading && (isDragActive || isEmpty);
  return (
    <Container
      {...getRootProps()}
      isUploading={isUploading}
      isDragActive={isDragActive}
      shouldShowDropMessage={shouldShowDropMessage}
    >
      <input {...getInputProps()} />
      <div className="drop-empty-message">
        <UploadIcon width="40" />
        {message && <span>{message}</span>}
      </div>
      <div className="drop-content">
        {typeof children === 'function' ? children({ openDialog }) : children}
      </div>
      {isUploading && (
        <SpinningLoading
          text="Uploading..."
          Ico={null}
          textStyle={{ margin: 0 }}
        />
      )}
    </Container>
  );
};

export default Dropzone;

const Container = styled.div<{
  isUploading: boolean;
  isDragActive: boolean;
  shouldShowDropMessage: boolean;
}>`
  border-radius: 8px;
  background: ${({ isDragActive }) => (isDragActive ? '#484848' : '#262630')};
  padding: 24px;
  cursor: ${({ shouldShowDropMessage }) =>
    shouldShowDropMessage ? 'pointer' : 'default'};
  min-height: 140px;
  position: relative;
  transition: 0.2s;

  ${({ shouldShowDropMessage }) =>
    shouldShowDropMessage &&
    css`
      &:hover {
        background: #484848;
      }
    `}

  .drop-empty-message {
    position: absolute;
    top: 10px;
    left: 10px;
    right: 10px;
    bottom: 10px;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border: 2px dashed
      ${({ isDragActive }) => (isDragActive ? '#848484' : 'transparent')};
    border-radius: 4px;
    color: #f3e9d7;
    padding: 12px;
    transition: 0.2s;
    opacity: ${({ shouldShowDropMessage }) => (shouldShowDropMessage ? 1 : 0)};
    pointer-events: ${({ shouldShowDropMessage }) =>
      shouldShowDropMessage ? 'all' : 'none'};
    > span {
      text-align: center;
      font-size: 16px;
      font-weight: 400;
    }
  }

  .drop-content {
    opacity: ${({ isUploading, isDragActive }) =>
      isUploading || isDragActive ? 0.4 : 1};
  }
`;
