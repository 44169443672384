import styled from 'styled-components';

export function UnsplashAttribution() {
  return (
    <Wrapper>
      Photos by{' '}
      <AttributionLink
        target="_blank"
        href="https://unsplash.com/?utm_source=arbor&utm_medium=referral"
      >
        Unsplash
      </AttributionLink>
    </Wrapper>
  );
}

export function AuthorAttribution({
  author,
  link,
}: {
  author: string;
  link: string;
}) {
  return (
    <AuthorWrapper>
      Photo by{' '}
      <AuthorAttributionLink
        target="_blank"
        href={`${link}?utm_source=arbor&utm_medium=referral`}
      >
        {author}
      </AuthorAttributionLink>
    </AuthorWrapper>
  );
}

const Wrapper = styled.div`
  color: #bdbdbd;
  font-size: 10px;
  font-weight: 500;
  line-height: normal;
`;

const AttributionLink = styled.a`
  color: #f2d093 !important;
  font-size: 10px;
  font-weight: 600;
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-decoration-skip-ink: none;
  text-decoration-thickness: auto;
  text-underline-offset: auto;
  text-underline-position: from-font;
`;

const AuthorWrapper = styled.div`
  color: #f3e9d7;
  font-size: 9px;
  font-weight: 300;
  line-height: 159%;
  margin-top: 5px;
`;

const AuthorAttributionLink = styled.a`
  color: #f2d093 !important;
  font-size: 9px;
  font-weight: 500;
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-decoration-skip-ink: none;
  text-decoration-thickness: auto;
  text-underline-offset: auto;
  text-underline-position: from-font;
`;
