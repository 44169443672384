import React from 'react';
type Props = {
  width?: string;
  height?: string;
  viewBox?: string;
};
const UploadIcon = (props: Props) => {
  const { width = '40', height = '40', viewBox = '0 0 40 40' } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
    >
      <path
        d="M26.8084 26.1608H30.6084C30.6084 26.1608 37.4167 25.2725 37.4167 18.5877C37.4174 17.5705 37.2129 16.5637 36.8152 15.6275C36.4175 14.6913 35.8349 13.8451 35.1023 13.1394C34.3698 12.4337 33.5023 11.8832 32.5519 11.5208C31.6015 11.1585 30.5877 10.9917 29.5713 11.0305C28.5686 8.90225 26.8854 7.16876 24.7877 6.10378C22.69 5.03881 20.2972 4.70305 17.9874 5.14954C15.6776 5.59604 13.5823 6.79934 12.0326 8.56937C10.4829 10.3394 9.56704 12.5753 9.42971 14.9239C8.60343 14.7501 7.7488 14.7628 6.92805 14.9611C6.10731 15.1593 5.34111 15.5381 4.68526 16.0699C4.02941 16.6017 3.50042 17.273 3.13681 18.0351C2.77321 18.7971 2.58414 19.6307 2.58337 20.475C2.58337 26.2463 8.64121 26.156 8.64121 26.156H13.1743"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20 17.4524V34.8691"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.75 22.2024L20 17.4524L15.25 22.2024"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default UploadIcon;
