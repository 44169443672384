import { useEffect, useState } from 'react';
import styled from 'styled-components';

type Props = {
  height?: number;
  width?: string | number;
  auto?: boolean;
  autoDuration?: number;
  autoIncrementInterval?: number;
  finished?: boolean;
  max?: number;
  value?: number;
};

const ProgressBar = (props: Props) => {
  const {
    height = 8,
    width = '100%',
    auto,
    autoDuration = 5000,
    autoIncrementInterval = 500,
    finished,
    value: propsValue = 0,
    max = 100,
  } = props;
  const [value, setValue] = useState(propsValue);

  useEffect(() => {
    if (auto) {
      const interval = setInterval(() => {
        setValue((prev) => {
          const randomIncrement = Math.floor(
            Math.random() * (max / (autoDuration / autoIncrementInterval)),
          );
          if (prev + randomIncrement >= max) {
            clearInterval(interval);
            return max;
          }
          return prev + randomIncrement;
        });
      }, autoIncrementInterval);
      return () => clearInterval(interval);
    }
  }, []);

  useEffect(() => {
    if (auto && finished) setValue(max);
  }, [finished]);

  useEffect(() => {
    if (auto && props.autoDuration)
      setTimeout(() => setValue(max), autoDuration);
  }, []);

  return (
    <SProgress
      height={height}
      width={width}
      progress={(auto ? value : propsValue) / max}
    >
      <div />
    </SProgress>
  );
};

const SProgress = styled.div<{
  height: number;
  width: string | number;
  progress: number;
}>`
  height: ${({ height }) => height}px;
  width: ${({ width }) => (typeof width === 'number' ? `${width}px` : width)};
  background-color: #d7d7e1;
  border-radius: 9999px;

  > div {
    background-color: #17c964;
    border-radius: 9999px;
    height: 100%;
    width: ${({ progress }) => progress * 100}%;
    transition: width 0.2s;
  }
`;

export default ProgressBar;
