// Import necessary types and utilities
import { TranscriptElement } from '../../types.ts/transcript';
import ChangeHandler from './ChangeHandler';
import { TranscriptChange } from '../../types.ts/transcript';

class ReplaceTsHandler extends ChangeHandler {
  apply(
    transcriptionElementsMutable: TranscriptElement[],
    change: TranscriptChange & { type: 'replace_ts' },
  ) {
    const index = change.index;
    const element = transcriptionElementsMutable[index];

    // Set the start timestamp as per the change
    element.ts = change.newTs;

    // Set the end timestamp on the left most text element to the new start timestamp
    transcriptionElementsMutable[index + 1].end_ts = change.newEndTs;
  }
}

export default ReplaceTsHandler;
