import { makeAutoObservable } from 'mobx';
import { videoCreator } from '../../stores/VideoCreatorStore';
import { Showcase, Story } from '../../types.ts/story';
import { Asset, ExtendedFile } from './types';
import { SimpleSchemaTypes } from '@datocms/cma-client-browser';
import { delay } from '../../utility/general';
import smartlook from 'smartlook-client';
import { PERSON_BY_EMAIL_QUERY } from '../../gql/misc-gql';

export class BrollService {
  public files: ExtendedFile[] = [];
  public assets: Asset[] = [];
  public progressStatus: 'uploaded' | 'uploading' | null = null;
  public isChecked = false;
  public organizationId: string | undefined = undefined;
  public storyId: string | undefined = undefined;
  public environment: string | null = null;
  public initialized: boolean = false;
  public contributor: Record<'name' | 'email', string> & { id?: string };
  public smartlookIdentified = false;

  constructor() {
    makeAutoObservable(this);

    const contributor = localStorage.getItem('broll-contributor');
    if (contributor && contributor !== '') {
      try {
        this.contributor = JSON.parse(contributor);
        if (!this.validateEmail(this.contributor.email)) {
          throw new Error('Invalid email');
        }
      } catch (error) {
        localStorage.removeItem('broll-contributor');
        this.contributor = { name: '', email: '' };
      }
    } else {
      this.contributor = { name: '', email: '' };
    }
  }

  public identifyContributor() {
    if (
      !this.contributor.name ||
      !this.contributor.email ||
      this.smartlookIdentified
    ) {
      return;
    }
    console.log('identifying contributor');

    smartlook.identify(this.contributor.email, {
      email: this.contributor.email,
      name: this.contributor.name,
    });
    this.smartlookIdentified = true;
  }

  async findOne() {
    if (this.organizationId) {
      const org = await videoCreator.albumRepository?.findOne(
        this.organizationId,
      );
      if (org) {
        videoCreator.organization = org;
        return {
          photos: org.organizationArtifacts,
          videos: org.organizationArtifactsVideo,
        };
      }
      return null;
    } else if (this.storyId) {
      const story = await videoCreator.findOneStory(this.storyId);
      if (story) {
        videoCreator.story = story;
        return {
          photos: story.storyArtifacts,
          videos: story.storyArtifactsVideo,
        };
      }
      return null;
    } else {
      throw new Error(
        'Invalid parameter. Either organizationId or storyId must be provided.',
      );
    }
  }

  private async findContributor() {
    const response = (await videoCreator.gqlClient?.request(
      PERSON_BY_EMAIL_QUERY,
      {
        email: this.contributor.email,
      },
    )) as { allPeople: Record<'id' | 'name', string>[] };

    if (response?.allPeople?.length) {
      const matchingPerson = response.allPeople.find(
        (p) => p.name === this.contributor.name,
      );
      if (matchingPerson) {
        this.contributor.id = matchingPerson.id;
      } else {
        const id = response.allPeople[0].id;
        this.contributor.id = id;
        await videoCreator.datoClient?.items.update(id, {
          name: this.contributor.name,
        });
      }
    }
  }

  private async saveContributor() {
    await this.findContributor();
    if (!this.contributor) return;

    if (!this.contributor.id) {
      const itemType = await videoCreator.datoClient?.itemTypes.find('person');
      const savedContributor = await videoCreator.datoClient?.items.create({
        item_type: { type: 'item_type', id: itemType!.id },
        name: this.contributor.name,
        email: this.contributor.email,
      });
      if (savedContributor) this.contributor.id = savedContributor.id;
    }
  }

  private async getDataToUpdate() {
    const storyToUpdate = { ...(videoCreator.story || {}) } as Story;
    const albumToUpdate = { ...(videoCreator.organization || {}) } as Showcase;

    await this.saveContributor();

    if (!this.contributor.id) return { storyToUpdate, albumToUpdate };

    if (this.organizationId) {
      albumToUpdate.contributors = [
        ...new Set([
          ...(videoCreator.organization?.contributors || []),
          this.contributor!,
        ]),
      ] as Record<'id' | 'email' | 'name', string>[];
    } else {
      storyToUpdate.contributors = [
        ...new Set([
          ...(videoCreator.story?.contributors || []),
          this.contributor,
        ]),
      ] as Record<'id' | 'email' | 'name', string>[];
    }

    return { storyToUpdate, albumToUpdate };
  }

  public getFileFormat(file: ExtendedFile): 'photo' | 'video' {
    const f = file.type.toLowerCase();
    if (f.includes('video') || f.includes('mp4') || f.includes('m4v')) {
      return 'video';
    }
    return 'photo';
  }

  async handleUpdate(
    file: ExtendedFile,
    upload: SimpleSchemaTypes.Upload,
    storyToUpdate: Story | null,
    albumToUpdate: Showcase | null,
  ) {
    const saved_assets = JSON.parse(
      sessionStorage.getItem('saved_assets') || '{}',
    );
    if (this.organizationId && albumToUpdate) {
      const artifactType = upload.is_image
        ? 'organizationArtifacts'
        : 'organizationArtifactsVideo';

      saved_assets[artifactType] = [
        ...(saved_assets[artifactType] || []),
        { id: upload.id!, title: file.description },
      ];

      sessionStorage.setItem('saved_assets', JSON.stringify(saved_assets));
      const existingArtifacts =
        videoCreator.organization?.[artifactType]?.map((a) => ({
          id: a.id,
          title: a.title,
        })) || [];
      const newArtifacts = [
        ...existingArtifacts,
        ...saved_assets[artifactType],
      ];

      albumToUpdate[artifactType] = newArtifacts;
      await videoCreator.albumRepository?.update(albumToUpdate);
    } else if (this.storyId && storyToUpdate) {
      const artifactType = upload.is_image
        ? 'storyArtifacts'
        : 'storyArtifactsVideo';

      saved_assets[artifactType] = [
        ...(saved_assets[artifactType] || []),
        { id: upload.id!, title: file.description },
      ];

      sessionStorage.setItem('saved_assets', JSON.stringify(saved_assets));
      const existingArtifacts =
        videoCreator.story?.[artifactType]?.map((a) => ({
          id: a.id,
          title: a.title,
        })) || [];
      const newArtifacts = [
        ...existingArtifacts,
        ...saved_assets[artifactType],
      ];

      storyToUpdate[artifactType] = newArtifacts;
      await videoCreator.updateStory(storyToUpdate);
    }
  }

  private async uploadFilesAndAsset(
    files: (File & { preview?: string; id?: string; author?: string })[],
    cb: (
      file: File & { preview: string; id: string; description: string },
      upload: SimpleSchemaTypes.Upload,
    ) => void = () => {},
  ) {
    await Promise.all(
      files.map(async (file) => {
        const namesplit = file?.name?.split('.');
        const fileName = namesplit?.length
          ? namesplit[0]
          : window.crypto.randomUUID();
        const newFileData = {
          type: 'artifact' as 'artifact',
          file,
          fileName,
          author: file.author,
          alt: '',
          title: '',
          tags:
            this.contributor.email && this.contributor.name
              ? [this.contributor.email, this.contributor.name]
              : undefined,
          customData: {
            "Contributor's Name": this.contributor.name,
            "Contributor's Email": this.contributor.email,
          },
        };

        const newUpload =
          await videoCreator.assetRepository?.uploadFile(newFileData);
        cb(
          file as File & { preview: string; id: string; description: string },
          newUpload!,
        );
        return newUpload;
      }),
    );
  }

  async saveBroll() {
    if (
      !this.isChecked ||
      !this.files.length ||
      (!this.storyId && !this.organizationId) ||
      !this.environment ||
      this.progressStatus === 'uploading'
    ) {
      return;
    }
    this.progressStatus = 'uploading';

    this.assets = this.assets.map((p) => {
      if (p.state === 'pending') {
        return { ...p, state: 'uploading' };
      }
      return p;
    });
    const files = this.files;
    this.files = [];
    window.scrollTo(0, 0);

    const { storyToUpdate, albumToUpdate } = await this.getDataToUpdate();

    await this.uploadFilesAndAsset(files, async (file, upload) => {
      if (!upload) return;
      await this.handleUpdate(
        file as ExtendedFile,
        upload,
        storyToUpdate,
        albumToUpdate,
      );

      this.assets = this.assets.map((p) => {
        if (p.id === file.id) {
          return {
            ...p,
            // src: upload.url,
            ...(upload.is_image && { imgSrc: upload.url }),
            ...(!upload.is_image && { videoSrc: upload.url }),
            isPreview: false,
            id: upload.id,
            saved: true,
            state: 'uploaded',
            description: file.description,
          };
        }
        return p;
      });
    });

    await delay(20);
    // await updateStory(newStory!);
    await this.findOne();
    this.progressStatus = 'uploaded';
    sessionStorage.removeItem('saved_assets');
    this.isChecked = false;
  }

  async removeAsset(setIsDeleting: (e: boolean) => void, asset: Asset) {
    this.files = this.files.filter((p) => p.id !== asset.id);
    if (asset.isPreview) {
      this.assets = this.assets.filter((a) => a.id !== asset.id);
      return;
    }
    setIsDeleting(true);

    if (videoCreator.organization) {
      const key =
        asset.type === 'photo'
          ? 'organizationArtifacts'
          : 'organizationArtifactsVideo';
      videoCreator.organization![key] = videoCreator.organization![key]?.filter(
        (f) => f.id !== asset.id,
      );
      await videoCreator.albumRepository?.update(videoCreator.organization);
    } else {
      const key =
        asset.type === 'photo' ? 'storyArtifacts' : 'storyArtifactsVideo';
      videoCreator.story![key] = videoCreator.story![key]?.filter(
        (f) => f.id !== asset.id,
      );
      await videoCreator.updateStory(videoCreator.story!);
    }

    this.assets = this.assets.filter((a) => a.id !== asset.id);
    setIsDeleting(false);
  }

  async changeDescription(asset: Asset, description: string) {
    if (asset.description === description || asset.isPreview) return;

    await this.findOne();
    await videoCreator.assetRepository?.update(asset.id, {
      title: description,
      alt: description,
    });
    if (videoCreator.organization) {
      const key =
        asset.type === 'photo'
          ? 'organizationArtifacts'
          : 'organizationArtifactsVideo';
      videoCreator.organization![key] = videoCreator.organization![key]?.map(
        (f) => (f.id === asset.id ? { ...f, title: description } : f),
      );
      await videoCreator.albumRepository?.update(videoCreator.organization!);
    } else {
      const key =
        asset.type === 'photo' ? 'storyArtifacts' : 'storyArtifactsVideo';
      videoCreator.story![key] = videoCreator.story![key]?.map((f) =>
        f.id === asset.id ? { ...f, title: description } : f,
      );
      await videoCreator.updateStory(videoCreator.story!);
    }
    this.assets = this.assets.map((p) =>
      p.id === asset.id ? { ...p, description } : p,
    );
    await delay(20);
    videoCreator.toastState = {
      state: 'success',
      message: 'Description Saved',
    };
  }

  public validateEmail(inputString: string) {
    const regex = /^[\w-]+(?:\.[\w-]+)*@(?:[\w-]+\.)+[a-zA-Z]{2,}$/;
    return inputString.match(regex);
  }

  public setContributor(contributor: Record<'name' | 'email', string>) {
    if (this.validateEmail(contributor.email) && contributor.name) {
      this.contributor = contributor;
      localStorage.setItem('broll-contributor', JSON.stringify(contributor));
    }
  }
}

export const brollService = new BrollService();
