import styled, { css } from 'styled-components';
import CircleCheckIcon from '../../svgs/CircleCheckIcon';
import RotatingSquare from '../../svgs/RotatingSquare';
import { ImageKey, ImageWithType } from '../../types.ts/general';
import { trackDownload } from '../../utility/unsplash';

type Props = {
  replaceAction: () => void;
  title: string;
  isSelected: boolean;
  selectedImage?: ImageWithType[ImageKey] | null;
  origin?: string;
};

const PhotoModalTop = (props: Props) => {
  const isTranscriptPhoto = props.origin === 'transcript';

  const handleReplaceAction = () => {
    recordSelectedImageUsage();
    props.replaceAction();
  };

  const recordSelectedImageUsage = () => {
    if (props.selectedImage?.type === 'stock') {
      trackDownload(props.selectedImage.attribution?.download_location);
    }
  };

  return (
    <>
      <Title origin={props.origin}>{props.title}</Title>
      {!isTranscriptPhoto && (
        <>
          <SubTitle>Select the image you'd like to use instead</SubTitle>
        </>
      )}
      <ReplaceButton
        isSelected={props.isSelected}
        isTranscriptPhoto={isTranscriptPhoto}
        onClick={handleReplaceAction}
      >
        {isTranscriptPhoto && (
          <RotatingSquare
            strokeColor={!props.isSelected ? '#484848' : undefined}
          />
        )}
        Add Image{' '}
        {!isTranscriptPhoto && (
          <CircleCheckIcon
            strokeColor={!props.isSelected ? '#484848' : undefined}
          />
        )}
      </ReplaceButton>
    </>
  );
};

export default PhotoModalTop;

const ReplaceButton = styled.button<{
  isSelected: boolean;
  isTranscriptPhoto: boolean;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  color: #484848;
  border: 1px solid #484848;
  background: #03041a;

  outline: 0;
  margin: 0 auto;
  margin-bottom: 15px;
  font-weight: 800;
  ${(props) =>
    props.isSelected &&
    css`
      background: #45d483;
      color: #03041a;
      border: 1px solid #45d483;
    `}

  ${(props) =>
    props.isTranscriptPhoto &&
    css`
      border-radius: 8px;
      width: 200px;
      height: 48px;
      gap: 8px;
      margin-bottom: 20px;
    `}

  ${(props) =>
    !props.isTranscriptPhoto &&
    css`
      border-radius: 30px;
      gap: 10px;
      padding: 8px;
      padding-left: 14px;
    `}
`;

const Title = styled.h3<{ origin?: string }>`
  text-align: center;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  ${(props) =>
    props.origin !== 'transcript' &&
    css`
      color: #45d483;
      font-size: 12px;
      font-weight: 500;
      letter-spacing: 1.92px;
      text-transform: uppercase;
      width: 60%;
    `}

  ${(props) =>
    props.origin === 'transcript' &&
    css`
      font-size: 20px;
      font-weight: 700;
      line-height: 24.2px;
    `}
`;

const SubTitle = styled.h5`
  color: #f3e9d7;
  text-align: center;
  font-size: 16px;
  font-weight: 700;
  line-height: normal;
`;
