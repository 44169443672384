type Props = {
  width?: string;
  height?: string;
  viewBox?: string;
};

const ShadowIcon = (props: Props) => {
  const { width = '24', height = '25', viewBox = '0 0 24 25' } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
    >
      <path
        d="M4 22.5C3.45 22.5 2.97933 22.3043 2.588 21.913C2.19667 21.5217 2.00067 21.0507 2 20.5V8.5C2 7.95 2.196 7.47933 2.588 7.088C2.98 6.69667 3.45067 6.50067 4 6.5H6V4.5C6 3.95 6.196 3.47933 6.588 3.088C6.98 2.69667 7.45067 2.50067 8 2.5H20C20.55 2.5 21.021 2.696 21.413 3.088C21.805 3.48 22.0007 3.95067 22 4.5V16.5C22 17.05 21.8043 17.521 21.413 17.913C21.0217 18.305 20.5507 18.5007 20 18.5H18V20.5C18 21.05 17.8043 21.521 17.413 21.913C17.0217 22.305 16.5507 22.5007 16 22.5H4ZM8 16.5H20V4.5H8V16.5Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default ShadowIcon;
