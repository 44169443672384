import type { AssociatedVideo, StoryVideo, VideoClip } from '../types.ts/story';
import { AspectRatio } from '../types.ts/video';

export const getAssociatedVideoWithStoryVideoField = (
  video: VideoClip,
  field: keyof StoryVideo['video'],
): AssociatedVideo | undefined => {
  const options = video.associatedVideos.filter(
    (v) => v.videoFilePrimary?.video?.[field],
  );
  if (!options.length) {
    return undefined;
  }
  return (
    options.find((o) => o.aspectRatio === AspectRatio.AR_16_9) || options[0]
  );
};

export const getThumbnailTime = (
  clipJson: Record<string, any>,
): number | undefined => {
  if (clipJson.startTime == undefined) return;
  return Math.ceil(Number(clipJson.startTime) + 3);
};
