import React from 'react';
import EditTimingModal from './EditTimingModal'; // Adjust the import path as necessary
import {
  getClosestNotRemovedTextIndexToLeft,
  getClosestNotRemovedTextIndexToRight,
} from '../../videoTranscriptionProcessor/utils';
import { videoCreator } from '../../stores/VideoCreatorStore';
import { TranscriptElement } from '../../types.ts/transcript';

interface EditTimingSectionProps {
  transcriptElements: TranscriptElement[];
  isSubtitlesTranslated: boolean;
  showEditTimingModal: boolean;
  sourceVideoFramerate: number;
  startIndex: number;
  endIndex: number;
  discard: () => void;
}

const EditTimingSection: React.FC<EditTimingSectionProps> = ({
  transcriptElements,
  isSubtitlesTranslated,
  showEditTimingModal,
  sourceVideoFramerate,
  startIndex,
  endIndex,
  discard,
}) => {
  const getCurrentText = (startIndex: number, endIndex: number): string => {
    return transcriptElements
      .slice(startIndex, endIndex + 1)
      .filter(
        (el: TranscriptElement) =>
          !['cut', 'muted', 'removed'].includes(el?.state || ''),
      )
      .map((el: TranscriptElement) => el.value)
      .join(' ');
  };

  const getCurrentTimestamp = (index: number) => {
    const closestIndex = getClosestNotRemovedTextIndexToRight(
      index,
      transcriptElements,
    );
    return closestIndex !== null ? transcriptElements[closestIndex].ts ?? 0 : 0;
  };

  const getCurrentEndTimestamp = (index: number) => {
    const closestIndex = getClosestNotRemovedTextIndexToLeft(
      index,
      transcriptElements,
    );
    return closestIndex !== null
      ? transcriptElements[closestIndex].end_ts ?? Infinity
      : Infinity;
  };

  const getBound = (index: number) => {
    // Start element is always text element; verified in Transcript component.
    const startElement = transcriptElements[index];

    const prevTextElementIndex = getClosestNotRemovedTextIndexToLeft(
      index - 1,
      transcriptElements,
    );
    const prevTextElement = transcriptElements[prevTextElementIndex];

    const nextTextElementIndex = getClosestNotRemovedTextIndexToRight(
      index + 1,
      transcriptElements,
    );
    const nextTextElement = transcriptElements[nextTextElementIndex];

    return {
      // ts: Previous element end_ts or start_ts of the first element.
      ts: prevTextElement?.end_ts || startElement.ts || 0,
      // end_ts: Next element start_ts or end_ts of the current element or last element's end_ts.
      end_ts:
        nextTextElement?.ts ||
        startElement.end_ts ||
        transcriptElements[transcriptElements.length - 1].end_ts ||
        Infinity,
    };
  };

  const handleEditTiming = () => {
    if (!showEditTimingModal || !transcriptElements) return null;
    if (transcriptElements[startIndex]?.type !== 'text') return null;

    const changeTimestamp = (
      updatedTimestamp: number,
      updatedEndTimestamp: number,
    ) => {
      videoCreator.updateTranscriptionElement(startIndex, endIndex, {
        start_ts: updatedTimestamp,
        end_ts: updatedEndTimestamp,
      });
    };

    return (
      <EditTimingModal
        fps={sourceVideoFramerate}
        currentText={getCurrentText(startIndex, endIndex)}
        currentTimestamp={getCurrentTimestamp(startIndex)}
        currentEndTimestamp={getCurrentEndTimestamp(endIndex)}
        startBound={getBound(startIndex)}
        endBound={getBound(endIndex)}
        changeTimestamp={changeTimestamp}
        discard={discard}
      />
    );
  };

  return showEditTimingModal ? handleEditTiming() : null;
};

export default EditTimingSection;
