import {
  AlbumQueryResult,
  AlbumStory,
  DatoAlbumQueryResult,
  DatoShowcase,
  DatoStoryQueryResult,
  Showcase,
  Story,
  StoryQueryResult,
  StoryVideo,
  Transcription,
  Video,
} from '../types.ts/story';
import { deepClone } from './deepClone';

const THUMBNAILS_TIMECODE_LENGTH = 6;

export const getAiDescriptionResponse = (story: AlbumStory) => {
  return story?.aiResponse?.responses?.find(
    (response) => response.title === 'Description',
  );
};

export const updateStoryShowcase = (
  storyData: Story,
  album: Showcase,
): void => {
  storyData.primaryShowcase = album;
  const showcaseIdx = storyData._allReferencingShowcases?.findIndex(
    (s) => s.id === album.id,
  );
  if (showcaseIdx > -1) {
    storyData._allReferencingShowcases[showcaseIdx] = album;
  } else {
    storyData._allReferencingShowcases.push(album);
  }
};

export async function requestMissingFields(
  storyId: string,
  fields: ('originalVideo' | 'transcription')[],
): Promise<{ originalVideo?: StoryVideo; transcription?: Transcription }> {
  const url = `${process.env.REACT_APP_API_URL}/api/story/${storyId}/populate`;
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ fields }),
  });
  if (response.ok) {
    return (await response.json()).data;
  } else {
    throw new Error('Failed to fetch video');
  }
}

export async function populateShowcaseStoriesWithOriginalVideo(
  showcase: DatoShowcase,
): Promise<Showcase> {
  const storiesWithOriginalVideo: Showcase['stories'] = await Promise.all(
    showcase.stories.map(async (story) => {
      const selfHostedVideo = (
        await requestMissingFields(story.id, ['originalVideo'])
      ).originalVideo;
      return {
        ...story,
        originalVideo: story.useDatoVideo
          ? story.originalVideo
          : selfHostedVideo!,
        // Temporary added to support both self hosted and dato hosted videos
        selfHostedVideo: selfHostedVideo,
        datoHostedVideo: story.originalVideo,
      };
    }),
  );
  return {
    ...showcase,
    stories: storiesWithOriginalVideo,
  };
}

export async function populateAlbumQueryStoriesWithOriginalVideo(
  album: DatoAlbumQueryResult,
): Promise<AlbumQueryResult> {
  const storiesWithOriginalVideo: AlbumQueryResult['stories'] =
    await Promise.all(
      album.stories.map(async (story) => {
        const selfHostedVideo = (
          await requestMissingFields(story.id, ['originalVideo'])
        ).originalVideo;
        return {
          ...story,
          originalVideo: story.useDatoVideo
            ? story.originalVideo
            : selfHostedVideo!,
          // Temporary added to support both self hosted and dato hosted videos
          selfHostedVideo: selfHostedVideo,
          datoHostedVideo: story.originalVideo,
        };
      }),
    );
  return {
    ...album,
    stories: storiesWithOriginalVideo,
  };
}

export async function populateStoryQueryWithMissingFields(
  story: DatoStoryQueryResult['story'],
): Promise<StoryQueryResult['story']> {
  if (!story) {
    return story;
  }

  const {
    originalVideo: selfHostedVideo,
    transcription: selfHostedTranscription,
  } = await requestMissingFields(story.id, ['originalVideo', 'transcription']);
  return {
    ...story,
    transcription: story.useDatoVideo
      ? story.transcription
      : selfHostedTranscription!,
    originalVideo: story.useDatoVideo ? story.originalVideo : selfHostedVideo!,
    // Temporary added to support both self hosted and dato hosted videos
    selfHostedVideo: selfHostedVideo,
    datoHostedVideo: story.originalVideo,
  };
}

export function getSelfHostedThumbnailUrl(
  originalVideo: StoryVideo,
  timecode?: number,
): string {
  const thumbnailUrl = originalVideo.video.thumbnailUrl;
  if (!timecode) return thumbnailUrl;
  const withTimeCode = thumbnailUrl.replace(
    'thumbnail',
    `thumbnails/${Math.round(timecode)
      .toString()
      .padStart(THUMBNAILS_TIMECODE_LENGTH, '0')}`,
  );
  return withTimeCode;
}

export function getMuxHostedThumbnailUrl(
  muxPlaybackId: string,
  timecode?: number,
) {
  let result = `https://image.mux.com/${muxPlaybackId}/thumbnail.jpg?height=187`;
  if (timecode) {
    result += `&time=${timecode}`;
  }
  return result;
}

type SignatureParams = {
  Policy: string;
  Signature: string;
  'Key-Pair-Id': string;
};

function pullSignatureParamsFromOriginalVideo(
  originalVideo: StoryVideo,
): SignatureParams | null {
  const originalVideoUrl = new URL(originalVideo.url);
  const policy = originalVideoUrl.searchParams.get('Policy');
  const signature = originalVideoUrl.searchParams.get('Signature');
  const keyPairId = originalVideoUrl.searchParams.get('Key-Pair-Id');
  if (!policy || !signature || !keyPairId) {
    console.error('Some signature params are missing from original video URL');
    return null;
  } else {
    return {
      Policy: policy,
      Signature: signature,
      'Key-Pair-Id': keyPairId,
    };
  }
}

function applySignatureParamsToUrl(
  inputUrl: string,
  signatureParams: SignatureParams,
): string {
  const parsedUrl = new URL(inputUrl);
  parsedUrl.searchParams.set('Policy', signatureParams.Policy);
  parsedUrl.searchParams.set('Signature', signatureParams.Signature);
  parsedUrl.searchParams.set('Key-Pair-Id', signatureParams['Key-Pair-Id']);
  return parsedUrl.toString();
}
