import { TranscriptChange, TranscriptElement } from '../../types.ts/transcript';
import {
  getClosestElementIndexToRightByFilter,
  getClosestElementIndexToLeftByFilter,
  getClosestNotRemovedElementIndexToRight,
} from '../utils';
import ChangeHandler from './ChangeHandler';

class KaraokeBreaksChangeHandler extends ChangeHandler {
  apply(
    transcriptionElementsMutable: TranscriptElement[],
    change: TranscriptChange,
  ) {
    switch (change.type) {
      case 'add_karaoke_break':
        this.applyAddKaraokeBreakChange(transcriptionElementsMutable, change);
        break;
      case 'remove_karaoke_break':
        this.applyRemoveKaraokeBreakChange(
          transcriptionElementsMutable,
          change,
        );
        break;
      case 'change_karaoke_end_break_time':
      case 'change_karaoke_start_break_time':
        this.applyChangeKaraokeBreakTime(transcriptionElementsMutable, change);
        break;
      case 'remove_all_karaoke_breaks':
        this.applyRemoveAllKaraokeBreaksChange(transcriptionElementsMutable);
        break;
    }
  }

  private applyAddKaraokeBreakChange(
    transcriptionElementsMutable: TranscriptElement[],
    change: TranscriptChange & { type: 'add_karaoke_break' },
  ) {
    if (Array.isArray(change.index)) {
      for (let i of change.index) {
        transcriptionElementsMutable[i].karaoke_break = true;

        // // Find previous and next karaoke break.
        // const previousKaraokeBreakIndex = getClosestElementIndexToLeftByFilter(
        //   i - 1,
        //   transcriptionElementsMutable,
        //   (el) => el.karaoke_break || false,
        // );

        // const nextKaraokeBreakIndex = getClosestElementIndexToRightByFilter(
        //   i + 1,
        //   transcriptionElementsMutable,
        //   (el) => el.karaoke_break || false,
        // );

        // // Since we're adding a line break, we need to duplicate start/end karaoke timings across both clips.
        // let karaokeStartTsDiffIndex, karaokeEndTsDiffIndex;

        // // Start at our previous break and go right, looking for the first element with a karaoke_break_start_ts_diff
        // // Start either at the previous karaoke break or the start of the transcript ( -1 + 1 = 0).
        // karaokeStartTsDiffIndex = getClosestElementIndexToRightByFilter(
        //   previousKaraokeBreakIndex + 1,
        //   transcriptionElementsMutable,
        //   (el) => el.karaoke_break_start_ts_diff !== undefined || false,
        // );

        // // Now start at our next karaoke break and go left, looking for the first element with a karaoke_break_end_ts_diff
        // // Start either at the next karaoke break or the end of the transcript.
        // const nextKaraokeTimeStartIndex =
        //   nextKaraokeBreakIndex < 0
        //     ? transcriptionElementsMutable.length
        //     : nextKaraokeBreakIndex - 1;

        // karaokeEndTsDiffIndex = getClosestElementIndexToLeftByFilter(
        //   nextKaraokeTimeStartIndex - 1,
        //   transcriptionElementsMutable,
        //   (el) => el.karaoke_break_end_ts_diff !== undefined || false,
        // );

        // // Now we shold have
        // // < prevBreak> | <TranscriptElement w/ startTsDiff> | <transcriptElements>... | <TranscriptElement w/ endTsDiff> | <nextBreak>

        // // Now add the end/start timings to elements before/after the karaoke break.
        // // We need to duplicate the timings for the new karaoke break.
        // if (karaokeEndTsDiffIndex > -1) {
        //   transcriptionElementsMutable[i - 1].karaoke_break_end_ts_diff =
        //     transcriptionElementsMutable[
        //       karaokeEndTsDiffIndex
        //     ].karaoke_break_end_ts_diff;
        // }

        // if (karaokeStartTsDiffIndex > -1) {
        //   transcriptionElementsMutable[i + 1].karaoke_break_start_ts_diff =
        //     transcriptionElementsMutable[
        //       karaokeStartTsDiffIndex
        //     ].karaoke_break_start_ts_diff;
        // }
      }
      return;
    }
    transcriptionElementsMutable[change.index].karaoke_break = true;
  }

  private applyRemoveKaraokeBreakChange(
    transcriptionElementsMutable: TranscriptElement[],
    change: TranscriptChange & { type: 'remove_karaoke_break' },
  ) {
    const elementsCount = change.count || 1;
    for (let i = 0; i < elementsCount; i++) {
      const changeIndex = change.index + i;
      delete transcriptionElementsMutable[changeIndex].karaoke_break;

      // Find previous and next karaoke break.
      const nextKaraokeBreakIndex = getClosestElementIndexToRightByFilter(
        changeIndex + 1,
        transcriptionElementsMutable,
        (el) => el.karaoke_break || false,
      );

      const previousKaraokeBreakIndex = getClosestElementIndexToLeftByFilter(
        changeIndex - 1,
        transcriptionElementsMutable,
        (el) => el.karaoke_break || false,
      );

      // Delete all _end_ karaoke timings between previous and this karaoke break
      // Since we're adding a line break, we need to duplicate the karaoke timings across both clips.
      // No OOB check since we're guaranteed to have a previous karaoke break or start of transcript.
      for (let j = previousKaraokeBreakIndex + 1; j < changeIndex; j++) {
        delete transcriptionElementsMutable[j].karaoke_break_end_ts_diff;
      }

      // Delete all _start_ karaoke timings between this and next karaoke break.
      if (nextKaraokeBreakIndex > -1) {
        for (let j = changeIndex; j < nextKaraokeBreakIndex; j++) {
          delete transcriptionElementsMutable[j].karaoke_break_start_ts_diff;
        }
      }
    }
  }

  private applyChangeKaraokeBreakTime(
    transcriptionElementsMutable: TranscriptElement[],
    change: TranscriptChange & {
      type: 'change_karaoke_start_break_time' | 'change_karaoke_end_break_time';
    },
  ) {
    if (change.type === 'change_karaoke_start_break_time') {
      transcriptionElementsMutable[change.index].karaoke_break_start_ts_diff =
        change.timeShift;
    } else {
      transcriptionElementsMutable[change.index].karaoke_break_end_ts_diff =
        change.timeShift;
    }
  }

  private applyRemoveAllKaraokeBreaksChange(
    transcriptionElementsMutable: TranscriptElement[],
  ) {
    for (let el of transcriptionElementsMutable) {
      delete el.karaoke_break;
      delete el.karaoke_break_start_ts_diff;
      delete el.karaoke_break_end_ts_diff;
    }
  }
}

export default KaraokeBreaksChangeHandler;
