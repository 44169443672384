import { createApi, Orientation } from 'unsplash-js';

const unsplashApi = createApi({
  apiUrl: `${process.env.REACT_APP_API_URL}/api/forward/unsplash`,
});

type UnsplashPhoto = {
  id: string;
  urls: {
    full: string;
    raw: string;
    regular: string;
    small: string;
    thumb: string;
  };
  width: number;
  height: number;
  alt_description: string | null;
  description: string | null;
  user: {
    name: string;
    links: {
      html: string;
    };
  };
  links: {
    download_location: string;
  };
};

export async function searchPhotos(params: {
  query: string;
  orientation?: Orientation;
  page?: number;
  perPage?: number;
}): Promise<{ photos: UnsplashPhoto[] | undefined }> {
  const { response } = await unsplashApi.search.getPhotos(params);
  return { photos: response?.results };
}

export async function trackDownload(downloadLocation: string | undefined) {
  if (!downloadLocation) {
    return;
  }
  return unsplashApi.photos.trackDownload({ downloadLocation });
}

export type Attribution = {
  author: string;
  link: string;
  download_location: string;
};

export function getAttributionForPhoto(photo: UnsplashPhoto): Attribution {
  return {
    author: photo.user.name,
    link: photo.user.links.html,
    download_location: photo.links.download_location,
  };
}
