import { useFlags } from 'launchdarkly-react-client-sdk';
import { videoCreator } from '../stores/VideoCreatorStore';

export function useFlagsCombination() {
  const isDatoUser = Boolean(videoCreator.datoContext.currentRole);

  const {
    contentStudioEnableSharing,
    contentStudioEnableClipProduceBtn,
    contentStudioEnableClipPublishBtn,
    contentStudioEnableEmailRegenerationBtn,
    contentStudioEnableShareableImageRegeneration,
    contentStudioEnableBlogRegeneration,
    contentStudioEnableTalkingPointsRegenerationBtn,
    contentStudioEnableEmailShareBtn,
    contentStudioEnableBlogShareBtn,
    contentStudioEnableShareableImageShareBtn,
    contentStudioEnableClipShareBtn,
    contentStudioEnableBlogSaveBtn,
    contentStudioEnableEmailSaveBtn,
    contentStudioEnableShareableImageSaveBtn,
    contentStudioEnableTalkingPointsSaveBtn,
    contentStudioEnableClipEdit,
    isEnterprisePlatform,
    enableCreatorStudio,
    hideStoryDashboardNav,
    enableImportXml,
    enableAiStoryProducer,
    enableFixTranscriptButton,
    storyUploadUseDatoVideo,
  } = useFlags();

  const enableB2B = isEnterprisePlatform || isDatoUser;

  function resolveB2BFlag(flag: 'on' | 'off' | 'unset') {
    if (isDatoUser) return true;
    if (flag === 'off') return false;
    return flag === 'on' || enableB2B;
  }

  function resolveAdminFlag(flag: 'on' | 'off' | 'unset') {
    return flag === 'on' || isDatoUser;
  }

  return {
    enableSharing: resolveB2BFlag(contentStudioEnableSharing),
    contentStudioEnableClipProducer: resolveB2BFlag(
      contentStudioEnableClipProduceBtn,
    ),
    contentStudioEnableClipPublish: resolveB2BFlag(
      contentStudioEnableClipPublishBtn,
    ),
    contentStudioEnableEmailShare: resolveB2BFlag(
      contentStudioEnableEmailShareBtn,
    ),
    contentStudioEnableBlogShare: resolveB2BFlag(
      contentStudioEnableBlogShareBtn,
    ),
    contentStudioEnableImageShare: resolveB2BFlag(
      contentStudioEnableShareableImageShareBtn,
    ),
    contentStudioEnableClipShare: resolveB2BFlag(
      contentStudioEnableClipShareBtn,
    ),
    contentStudioEnableBlogSave: resolveB2BFlag(contentStudioEnableBlogSaveBtn),
    contentStudioEnableEmailSave: resolveB2BFlag(
      contentStudioEnableEmailSaveBtn,
    ),
    contentStudioEnableShareableImageSave: resolveB2BFlag(
      contentStudioEnableShareableImageSaveBtn,
    ),
    isEnterprisePlatform: enableB2B || false,
    isDatoUser,
    storyUploadUseDatoVideo: storyUploadUseDatoVideo ?? false,
    enableCreatorStudioNav: resolveAdminFlag(enableCreatorStudio),
    hideStoryDashboardNav: hideStoryDashboardNav || false,
    enableBlogRegeneration: resolveB2BFlag(contentStudioEnableBlogRegeneration),
    enableEmailRegeneration: resolveB2BFlag(
      contentStudioEnableEmailRegenerationBtn,
    ),
    enableShareableImageRegeneration: resolveB2BFlag(
      contentStudioEnableShareableImageRegeneration,
    ),
    enableCaptionTemplatesTab: false,
    enableVideoClipEdit: resolveAdminFlag(contentStudioEnableClipEdit),
    enableTalkingPointsStudioSave: resolveB2BFlag(
      contentStudioEnableTalkingPointsSaveBtn,
    ),
    enableTalkingPointsStudioRegeneration: resolveB2BFlag(
      contentStudioEnableTalkingPointsRegenerationBtn,
    ),
    enableImportXml: resolveAdminFlag(enableImportXml),
    enableAiStoryProducer: resolveAdminFlag(enableAiStoryProducer),
    enableFixTranscriptButton: resolveAdminFlag(enableFixTranscriptButton),
  };
}
