import { MEDIA_QUERY_FRAGMENT } from './media-gql';

export const TEXT_BRANDING_QUERY = `
    id
    title
    fontFamily
    fontSize
    aspectRatio
    fillColor {
      cssRgb
      red
      green
      blue
      alpha
      hex
    }
    backgroundColor {
      cssRgb
      red
      green
      blue
      alpha
      hex
    }
    backgroundTransparency
    fontWeight
    uppercase
    italic
    xAlignment
    shadowColor {
      cssRgb
      red
      green
      blue
      alpha
      hex
    }
    shadowBlur
    shadowXOffset
    shadowYOffset
`;

export const BASIC_TEXT_BRANDING_QUERY = `{
  ${TEXT_BRANDING_QUERY}
  enterAnimation
  enterAnimationLength
  exitAnimation
  exitAnimationLength
  length
}`;

export const KARAOKE_TEXT_BRANDING_QUERY = `{
  ${TEXT_BRANDING_QUERY}
  xPos
  yPos
  strokeColor {
    cssRgb
    red
    green
    blue
    alpha
    hex
  }
  strokeWidth
  animation
  instagramLines
  hideComma
  hidePeriod
  hideFillers
}`;

export const SHOWCASE_BRANDING_QUERY = `{
  id
  title
  primaryColors
  secondaryColors
  brandFonts
  customFonts
  customFontFiles {
    id
    title
    url
  }
  designElements ${MEDIA_QUERY_FRAGMENT}
  brandVoice
  avoidWords
  missionStatement
  keyMetrics
  companyReports {
    id
    title
    format
    mimeType
    url
    customData
    _createdAt
  }
  organizationWebsite
  externalLinks
}`;
